<template>
	<div class="container flex f-column f-align">
		<span class="font60 black bold">服务项目费用</span>
		<div class="code-box f-center">
			<qriously :value="code" :size="getSize()" />
		</div>
		<div class="font30 black mt40">扫码支付</div>
		<p class="flex f-align font50 blue" style="margin-top: 4.6875rem;">
			<img class="mr20" src="../assets/image/weixinxuanzhong.png" style="width: 4.875rem;height: 4.25rem;">
			支付金额 ￥{{price}}
		</p>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				code: '',
				price: '',
				outTradeNo: '',
				type: 'wx',
				timer3: '',
			};
		},
		mounted() {
			this.$emit('change', '')
			this.code = this.$route.query.code
			this.outTradeNo = this.$route.query.outTradeNo
			this.price = parseFloat(this.$route.query.price) / 100
			this.getpaysta()
		},
		methods: {
			getSize() {
				return 260*(document.documentElement.clientWidth / 1080) 
			},
			getpaysta() {
				this.timer3 = setInterval(() => {
					this.$post("/wxPay/payStatus", {
						outTradeNo: this.outTradeNo,
					}).then(res => {
						if (res.code == 200) { 
							this.$router.push('./boxDoorOpen')
						}
					});
				}, 1000)
			}
		},
		beforeDestroy() {
			clearInterval(this.timer3)
		}
	};
</script>
<style scoped lang="scss">
	.container {
		padding-top: 40px;
		
		.code-box {
			width: 300px;
			height: 300px;
			border: 2px solid #000000;
			border-radius: 15px;
			margin-top: 45px;
		}
	}

	.tabbox {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-bottom: 50px;
	}

	.tab {
		font-size: 48px;
		color: #999999;
		font-weight: 600;
		margin: 0 40px;
		position: relative;
		z-index: 10;
	}

	.active {
		color: #1B1B28;
	}

	.active::after {
		content: '';
		display: block;
		width: 114px;
		height: 15px;
		opacity: 0.79;
		background: #fe9002;
		position: absolute;
		bottom: 3px;
		left: -10px;
		z-index: 1;
	}

	.payprice {
		font-size: 36px;
		color: #1B1B28;
	}

	.price {
		font-size: 60px;
		color: #FE9002;
	}

	.fh {
		color: #FE9002;
	}
</style>
